<!-- src/views/Projects.vue -->  
<template>  
  <div class="container">  
    <div id="app" class="projects-container">  
      <h2>Projekte</h2>  
      <div class="projects-list">  
        <div class="project-item" v-for="project in projects" :key="project.id">  
          <div class="project-item-photo" :style="{ backgroundImage: `url(${project.image})` }">  
            <router-link :to="{ name: 'ProjectDetail', params: { id: project.id } }"></router-link>  
          </div>  
          <div class="project-item-content">  
            <p>  
              <router-link :to="{ name: 'ProjectDetail', params: { id: project.id } }">  
                <strong>{{ project.title }}</strong>  
              </router-link>  
            </p>  
            <router-link :to="{ name: 'ProjectDetail', params: { id: project.id } }" class="view-more">  
              Mehr lesen <i class="fas fa-arrow-right text-light" aria-hidden="true"></i>  
            </router-link>  
          </div>  
        </div>  
      </div>  
    </div>  
  </div>  
</template>  
  
<script>  
export default {  
  name: 'ProjectsView',  
  data() {  
    return {  
      projects: [  
        {  
          id: 1,  
          title: 'Altbausanierung Weinsberg',  
          image: '@/assets/projects/altbausanierung_weinsberg.jpg',  
        },  
        {  
          id: 2,  
          title: 'Bau Fabrikhalle',  
          image: '@/assets/projects/bau_fabrikhalle.jpg',  
        },  
        {  
          id: 3,  
          title: 'Neubau EFH',  
          image: '@/assets/projects/neubau_efh.jpg',  
        },  
        {  
          id: 4,  
          title: 'Neubau MFH',  
          image: '@/assets/projects/neubau_mfh.jpg',  
        },  
      ],  
    };  
  },  
};  
</script>  
  
<style scoped>  
.projects-container {  
  max-width: 800px;  
  margin: 0 auto;  
  padding-top: 80px;  
  margin-top: 80px;  
  padding: 20px;  
  line-height: 1.6;  
}  
  
.projects-list {  
  display: flex;  
  flex-wrap: wrap;  
  gap: 20px;  
}  
  
.project-item {  
  background-color: #f8f9fa;  
  border-radius: 10px;  
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  
  overflow: hidden;  
  width: calc(50% - 10px);  
}  
  
.project-item-photo {  
  background-position: center;  
  background-size: cover;  
  height: 150px;  
  position: relative;  
}  
  
.project-item-content {  
  padding: 20px;  
  text-align: center;  
}  
  
.view-more {  
  color: #2980b9;  
  text-decoration: none;  
}  
  
.view-more:hover {  
  text-decoration: underline;  
}  
</style>  
