<template>  
    <div class="container">  
      <h1>Tragwerksplanung (Statik)</h1>  
      <p>Unsere Tragwerksplanung (Statik) bietet Ihnen umfassende Lösungen für Ihre Bauprojekte. Wir erstellen statische Berechnungen und Konzepte für Neubauten und Sanierungen, um die Sicherheit und Stabilität Ihrer Bauwerke zu gewährleisten.</p>  
        
      <h2>Unsere Leistungen:</h2>  
      <ul>  
        <li>Statische Berechnungen und Konzepte für Neubauten und Sanierungen</li>  
        <li>Erstellung von Tragwerksplänen</li>  
        <li>Beratung zu Materialwahl und Bauverfahren</li>  
        <li>Überprüfung der Tragfähigkeit bestehender Strukturen</li>  
        <li>Zusammenarbeit mit Architekten und Bauunternehmen</li>  
      </ul>  
        
      <h2>Warum wir?</h2>  
      <p>Unser Team aus erfahrenen Statikern und Ingenieuren arbeitet eng mit Ihnen zusammen, um die bestmöglichen Lösungen für Ihre Projekte zu finden. Wir setzen modernste Technologien ein, um präzise und effiziente Ergebnisse zu erzielen.</p>  
        
      <p>Kontaktieren Sie uns noch heute, um mehr über unsere umfassenden Dienstleistungen zu erfahren und ein unverbindliches Angebot zu erhalten.</p>  
    </div>  
  </template>  
    
  <script>  
  export default {  
    name: 'StructuralDesign',  
  };  
  </script>  
    
  <style scoped>  
  .container {  
    margin-top: 100px;  
    padding: 20px;  
    line-height: 1.6;  
  }  
    
  h1, h2 {  
    margin-bottom: 10px;  
  }  
    
  ul {  
    list-style-type: disc;  
    padding-left: 20px;  
  }  
    
  li {  
    margin-bottom: 10px;  
  }  
    
  p {  
    margin-bottom: 10px;  
  }  
  </style>  
  