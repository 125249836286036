// src/router/index.js  
import { createRouter, createWebHistory } from 'vue-router';  
import Home from '@/views/Home.vue';  
import Projects from '@/views/Projects.vue';  
import Impressum from '@/views/Impressum.vue';  
import AppDatenschutz from '@/views/Datenschutz.vue';  
import CareerPage from '@/views/CareerPage.vue';  
import JobDetail from '@/views/JobDetail.vue';  
import ProjectDetail from '@/views/ProjectDetail.vue';  
import ProjectDevelopment from '@/views/services/ProjectDevelopment.vue';  
import ConstructionSupervision from '@/views/services/ConstructionSupervision.vue';  
import StructuralDesign from '@/views/services/StructuralDesign.vue';  
import MeasurementServices from '@/views/services/MeasurementServices.vue';  
import EnergyConsulting from '@/views/services/EnergyConsulting.vue';  
import RenovationSupport from '@/views/services/RenovationSupport.vue';  
  
const routes = [  
  {  
    path: '/',  
    name: 'Home',  
    component: Home,  
  },  
  {  
    path: '/projects',  
    name: 'Projects',  
    component: Projects,  
  },  
  {  
    path: '/projects/:id',  
    name: 'ProjectDetail',  
    component: ProjectDetail,  
    props: true,  
  },  
  {  
    path: '/karriere',  
    name: 'CareerPage',  
    component: CareerPage,  
  },  
  {  
    path: '/karriere/:id',  
    name: 'JobDetail',  
    component: JobDetail,  
    props: true,  
  },  
  {  
    path: '/impressum',  
    name: 'Impressum',  
    component: Impressum,  
  },  
  {  
    path: '/datenschutz',  
    name: 'Datenschutz',  
    component: AppDatenschutz,  
  },  
  {  
    path: '/project-development',  
    name: 'ProjectDevelopment',  
    component: ProjectDevelopment,  
  },  
  {  
    path: '/construction-supervision',  
    name: 'ConstructionSupervision',  
    component: ConstructionSupervision,  
  },  
  {  
    path: '/structural-design',  
    name: 'StructuralDesign',  
    component: StructuralDesign,  
  },  
  {  
    path: '/measurement-services',  
    name: 'MeasurementServices',  
    component: MeasurementServices,  
  },  
  {  
    path: '/energy-consulting',  
    name: 'EnergyConsulting',  
    component: EnergyConsulting,  
  },  
  {  
    path: '/renovation-support',  
    name: 'RenovationSupport',  
    component: RenovationSupport,  
  },  
  {
    path: '/career',
    name: 'CareerPage',
    component: CareerPage
  },  
];  
  
const router = createRouter({  
  history: createWebHistory(process.env.BASE_URL),  
  routes,  
});  
  
export default router;  
