<!-- src/views/CareerPage.vue -->  
<template>  
  <div class="container">  
    <div id="app" class="projects-container">  
      <main class="career-page">  
        <section class="intro">  
          <h1>Karriere bei der Bau Art Consulting GmbH</h1>  
          <p>Willkommen auf unserer Karriereseite! Hier erfahren Sie mehr über unsere Unternehmenskultur und die offenen Stellen.</p>  
        </section>  
  
        <section class="values">  
          <h2>Unsere Werte</h2>  
          <p>Wir legen großen Wert auf Integrität, Zusammenarbeit und Innovation.</p>  
        </section>  
  
        <section class="job-list">  
          <h2>Offene Stellen</h2>  
          <ul>  
            <li v-for="job in jobs" :key="job.id">  
              <router-link :to="{ name: 'JobDetail', params: { id: job.id } }">{{ job.title }}</router-link>  
            </li>  
          </ul>  
        </section>  
  
        <section class="testimonials">  
          <h2>Was unsere Mitarbeiter sagen</h2>  
          <p>"Es ist ein tolles Gefühl, Teil eines so engagierten Teams zu sein." - Mitarbeiterin und Technische Zeichnerin</p>  
          <p>"Die Arbeitsatmosphäre ist sehr unterstützend und motivierend. Die Projekte bei der Bau Art Consulting sind sehr abwechsleungsreich." - Mitarbeiter und Energieeffizienzexperte</p>  
        </section>  
  
        <section class="contact">  
          <h2>Kontakt</h2>  
          <p>Haben Sie Fragen? Kontaktieren Sie uns unter <a href="mailto:info@bauartconsulting.de">info@bauartconsulting.de</a>.</p>  
        </section>  
      </main>  
    </div>  
  </div>  
</template>  
  
<script>  
export default {  
  name: 'CareerPage',  
  data() {  
    return {  
      jobs: [  
        { id: 1, title: 'Statiker (m/w/d)' },  
        { id: 2, title: 'Energieeffizienzexperte (m/w/d)' },  
        { id: 3, title: 'Technischer Bauzeichner (m/w/d)' }  
      ]  
    };  
  }  
};  
</script>  
  
<style scoped>  
.projects-container {  
  max-width: 800px;  
  margin: 0 auto;  
  padding-top: 80px;  
  margin-top: 80px;  
  padding: 20px;  
  line-height: 1.6;  
}  
  
.intro,  
.values,  
.job-list,  
.testimonials,  
.contact {  
  margin-bottom: 40px;  
}  
  
h1,  
h2 {  
  color: #595959;  
}  
  
ul {  
  list-style-type: disc;  
  padding-left: 20px;  
}  
  
li {  
  margin-bottom: 10px;  
}  
  
a {  
  color: #2980b9;  
  text-decoration: none;  
}  
  
a:hover {  
  text-decoration: underline;  
}  
</style>  
