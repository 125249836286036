<template>  
    <div class="container">  
      <h1>Energieberatung</h1>  
      <p>Unsere Energieberatung bietet Ihnen umfassende Lösungen zur Optimierung der Energieeffizienz Ihrer Gebäude. Wir analysieren den Energieverbrauch und entwickeln maßgeschneiderte Konzepte, um Energiekosten zu senken und den CO2-Ausstoß zu reduzieren.</p>  
        
      <h2>Unsere Leistungen:</h2>  
      <ul>  
        <li>Gebäudeanalyse und Erstellung von Energieausweisen</li>  
        <li>Beratung zu Einsparmaßnahmen und Fördermöglichkeiten</li>  
        <li>Unterstützung bei energetischen Sanierungen</li>  
        <li>Erstellung von Sanierungsfahrplänen</li>  
        <li>Überprüfung und Optimierung von Heizungs- und Lüftungssystemen</li>  
      </ul>  
        
      <h2>Warum wir?</h2>  
      <p>Unser Team aus zertifizierten Energieberatern verfügt über umfangreiche Erfahrung und Fachwissen im Bereich der Energieeffizienz. Wir arbeiten eng mit Ihnen zusammen, um individuelle Lösungen zu entwickeln, die sowohl ökologisch als auch ökonomisch sinnvoll sind.</p>  
        
      <p>Kontaktieren Sie uns noch heute, um mehr über unsere umfassenden Dienstleistungen zu erfahren und ein unverbindliches Angebot zu erhalten.</p>  
    </div>  
  </template>  
    
  <script>  
  export default {  
    name: 'EnergyConsulting',  
  };  
  </script>  
    
  <style scoped>  
  .container {  
    margin-top: 100px;  
    padding: 20px;  
    line-height: 1.6;  
  }  
    
  h1, h2 {  
    margin-bottom: 10px;  
  }  
    
  ul {  
    list-style-type: disc;  
    padding-left: 20px;  
  }  
    
  li {  
    margin-bottom: 10px;  
  }  
    
  p {  
    margin-bottom: 10px;  
  }  
  </style>  
  