<template>  
    <div class="container">  
      <h1>Messdienstleistungen</h1>  
      <p>Unsere Messdienstleistungen umfassen eine breite Palette von Prüf- und Analyseverfahren, um die Qualität und Effizienz Ihrer Bauprojekte zu gewährleisten. Wir bieten Ihnen professionelle Messungen und Analysen, die Ihnen helfen, potenzielle Probleme frühzeitig zu erkennen und zu beheben.</p>  
        
      <h2>Unsere Leistungen:</h2>  
      <ul>  
        <li>Blower-Door-Test: Luftdichtheit prüfen</li>  
        <li>Leckageortung: Luft- und Wasserlecks finden</li>  
        <li>Thermografie: Wärmebildanalysen</li>  
        <li>Schallmessungen</li>  
        <li>Feuchtigkeitsmessungen</li>  
      </ul>  
        
      <h2>Warum wir?</h2>  
      <p>Mit modernster Messtechnik und einem erfahrenen Team bieten wir Ihnen zuverlässige und präzise Messdienstleistungen. Unsere Experten stehen Ihnen mit Rat und Tat zur Seite, um die besten Lösungen für Ihre spezifischen Anforderungen zu finden.</p>  
        
      <p>Kontaktieren Sie uns noch heute, um mehr über unsere umfassenden Dienstleistungen zu erfahren und ein unverbindliches Angebot zu erhalten.</p>  
    </div>  
  </template>  
    
  <script>  
  export default {  
    name: 'MeasurementServices',  
  };  
  </script>  
    
  <style scoped>  
  .container {  
    margin-top: 100px;  
    padding: 20px;  
    line-height: 1.6;  
  }  
    
  h1, h2 {  
    margin-bottom: 10px;  
  }  
    
  ul {  
    list-style-type: disc;  
    padding-left: 20px;  
  }  
    
  li {  
    margin-bottom: 10px;  
  }  
    
  p {  
    margin-bottom: 10px;  
  }  
  </style>  
  