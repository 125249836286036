<template>  
    <div class="container">  
      <h1>Sanierungsbegleitung und Baumängelanalyse</h1>  
      <p>Unsere Sanierungsbegleitung und Baumängelanalyse hilft Ihnen, Baumängel frühzeitig zu erkennen und zu beheben. Wir begleiten Sie während des gesamten Sanierungsprozesses und stellen sicher, dass alle Arbeiten fachgerecht und nach den aktuellen Standards durchgeführt werden.</p>  
        
      <h2>Unsere Leistungen:</h2>  
      <ul>  
        <li>Identifikation und Bewertung von Baumängeln</li>  
        <li>Erstellung detaillierter Sanierungskonzepte</li>  
        <li>Planung und Überwachung von Sanierungsmaßnahmen</li>  
        <li>Qualitätssicherung und Dokumentation</li>  
        <li>Beratung zu Fördermöglichkeiten</li>  
      </ul>  
        
      <h2>Warum wir?</h2>  
      <p>Unsere erfahrenen Experten verfügen über das nötige Fachwissen und die technische Ausstattung, um Baumängel präzise zu erkennen und effektive Sanierungslösungen zu entwickeln. Wir legen großen Wert auf eine enge Zusammenarbeit mit unseren Kunden, um deren Anforderungen und Wünsche bestmöglich umzusetzen.</p>  
        
      <p>Kontaktieren Sie uns noch heute, um mehr über unsere umfassenden Dienstleistungen zu erfahren und ein unverbindliches Angebot zu erhalten.</p>  
    </div>  
  </template>  
    
  <script>  
  export default {  
    name: 'RenovationSupport',  
  };  
  </script>  
    
  <style scoped>  
  .container {  
    margin-top: 100px;  
    padding: 20px;  
    line-height: 1.6;  
  }  
    
  h1, h2 {  
    margin-bottom: 10px;  
  }  
    
  ul {  
    list-style-type: disc;  
    padding-left: 20px;  
  }  
    
  li {  
    margin-bottom: 10px;  
  }  
    
  p {  
    margin-bottom: 10px;  
  }  
  </style>  
  