<template>  
    <div class="container">  
      <h1>Projektentwicklung und -koordination</h1>  
      <p>Unsere Dienstleistungen im Bereich der Projektentwicklung und -koordination umfassen eine ganzheitliche Planung, Machbarkeitsstudien und Kostenanalysen. Wir unterstützen Sie bei der Planung und Umsetzung Ihrer Neubauprojekte und sorgen dafür, dass alle Prozesse reibungslos und effizient ablaufen.</p>  
        
      <h2>Unsere Leistungen:</h2>  
      <ul>  
        <li>Durchführung von Machbarkeitsstudien zur Bewertung der Projektidee</li>  
        <li>Erstellung detaillierter Kostenanalysen und Budgetpläne</li>  
        <li>Koordination aller am Bau beteiligten Akteure</li>  
        <li>Überwachung der Einhaltung von Zeitplänen und Budgets</li>  
        <li>Qualitätssicherung während der gesamten Projektlaufzeit</li>  
      </ul>  
        
      <h2>Warum wir?</h2>  
      <p>Mit unserer jahrelangen Erfahrung und unserem fundierten Fachwissen sind wir der ideale Partner für Ihre Bauprojekte. Wir legen großen Wert auf eine enge Zusammenarbeit mit unseren Kunden, um deren Visionen und Wünsche bestmöglich umzusetzen. Unsere Flexibilität, Termintreue und Bodenständigkeit sind die Grundpfeiler unserer Arbeit.</p>  
        
      <p>Kontaktieren Sie uns noch heute, um mehr über unsere umfassenden Dienstleistungen zu erfahren und ein unverbindliches Angebot zu erhalten.</p>  
    </div>  
  </template>  
    
  <script>  
  export default {  
    name: 'ProjectDevelopment',  
  };  
  </script>  
    
  <style scoped>  
  .container {  
    margin-top: 100px;  
    padding: 20px;  
    line-height: 1.6;  
  }  
    
  h1, h2 {  
    margin-bottom: 10px;  
  }  
    
  ul {  
    list-style-type: disc;  
    padding-left: 20px;  
  }  
    
  li {  
    margin-bottom: 10px;  
  }  
    
  p {  
    margin-bottom: 10px;  
  }  
  </style>  
  