<!-- src/views/JobDetail.vue -->    
<template>    
  <div class="container">    
    <div id="app" class="job-detail-container">    
      <main class="job-detail">    
        <h1>{{ job.title }}</h1>    
        <h2>{{ job.heading }}</h2>  
        <p>{{ job.description }}</p>    
        <h2>Verantwortlichkeiten</h2>    
        <ul class="centered-list">    
          <li v-for="responsibility in job.responsibilities" :key="responsibility">{{ responsibility }}</li>    
        </ul>    
        <h2>Anforderungen</h2>    
        <ul class="centered-list">    
          <li v-for="requirement in job.requirements" :key="requirement">{{ requirement }}</li>    
        </ul>    
        <h2>Benefits</h2>    
        <ul class="centered-list">    
          <li v-for="benefit in job.benefits" :key="benefit">{{ benefit }}</li>    
        </ul>    
        <h2>Kontakt</h2>  
        <p class="contact">  
          <a :href="'mailto:' + job.contactEmail">{{ job.contactEmail }}</a>  
        </p>  
      </main>    
    </div>    
    <Footer />    
  </div>    
</template>    
    
<script>    
export default {    
  name: 'JobDetail',      
  data() {    
    return {    
      jobs: [    
        {    
          id: 1,    
          title: 'Statiker*in (m/w/d)',    
          heading: 'Wir suchen eine*n erfahrene*n Statiker*in zur Verstärkung unseres Teams.',  
          description: '',    
          responsibilities: ['Projektplanung und -entwicklung', 'Überwachung von Baustellen', 'Beratung von Kund*innen', 'Erstellung von statischen Berechnungen', 'Zusammenarbeit mit Architekt*innen und Bauleiter*innen'],    
          requirements: ['Abgeschlossenes Studium im Bauingenieurwesen', 'Mehrjährige Berufserfahrung', 'Teamfähigkeit und Kommunikationsstärke'],    
          benefits: ['Flexible Arbeitszeiten', 'Weiterbildungsmöglichkeiten', 'Attraktives Gehalt'],    
          contactEmail: 'info@bauartconsulting.de'    
        },    
        {    
          id: 2,    
          title: 'Energieeffizienzexpert*in (m/w/d)',    
          heading: 'Wir suchen eine*n erfahrene*n Energieeffizienzexpert*in zur Unterstützung unserer Projekte.',  
          description: '',    
          responsibilities: ['Durchführung von Energieberatungen', 'Erstellung von Energieausweisen', 'Entwicklung von Sanierungsfahrplänen', 'Optimierung von Heizungs- und Lüftungssystemen'],    
          requirements: ['Abgeschlossenes Studium im Bereich Energie- oder Umwelttechnik', 'Berufserfahrung in der Energieberatung', 'Kenntnisse in Förderprogrammen', 'Gute Kommunikationsfähigkeiten'],    
          benefits: ['Flexible Arbeitszeiten', 'Weiterbildungsmöglichkeiten', 'Attraktives Gehalt', 'Homeoffice-Option'],    
          contactEmail: 'info@bauartconsulting.de'    
        },    
        {    
          id: 3,    
          title: 'Technische*r Bauzeichner*in (m/w/d)',    
          heading: 'Wir suchen eine*n engagierte*n Technische*n Bauzeichner*in zur Unterstützung unseres Teams.',  
          description: '',    
          responsibilities: ['Erstellung von Bauzeichnungen und Plänen', 'Unterstützung des Planungsteams', 'Dokumentation und Verwaltung von Projektdaten', 'Zusammenarbeit mit Ingenieur*innen und Architekt*innen'],    
          requirements: ['Abgeschlossene Ausbildung als Bauzeichner*in', 'Berufserfahrung im Bauwesen', 'Detailgenauigkeit und Organisationstalent'],    
          benefits: ['Flexible Arbeitszeiten', 'Weiterbildungsmöglichkeiten', 'Attraktives Gehalt', 'Modernes Arbeitsumfeld'],    
          contactEmail: 'info@bauartconsulting.de'    
        }    
      ]    
    };    
  },    
  computed: {    
    job() {    
      return this.jobs.find(job => job.id === parseInt(this.$route.params.id));    
    }    
  }    
}    
</script>    
    
<style scoped>    
.job-detail-container {    
  max-width: 800px;    
  margin: 0 auto;    
  padding-top: 80px;    
  margin-top: 80px;    
  padding: 20px;    
  line-height: 1.6;    
}    
    
h1, h2 {    
  color: #595959;    
  text-align: center;  
}    
    
.centered-list {    
  list-style-position: inside;    
  padding: 0;    
  display: flex;    
  flex-direction: column;    
  align-items: center;    
}    
    
.centered-list li {    
  margin: 10px 0;    
  text-align: left;    
  width: 70%; /* Adjust width as needed */    
}    
  
.contact {  
  text-align: center;  
  margin-top: 20px;  
}  
  
.contact a {  
  color: #2980b9;  
  text-decoration: none;  
  font-weight: bold;  
}  
  
.contact a:hover {  
  text-decoration: underline;  
}  
</style>  
