<template>    
  <div>    
    <section id="hero" class="parallax" :style="`background-image: url(${randomMainImage})`">    
      <div class="container">    
        <img src="@/assets/BAC_text_transparent_sharp.png" alt="Bau Art Consulting Logo" class="img-fluid" />    
      </div>    
    </section>    
    <section id="about" class="scroll-padding">    
      <div class="container">    
        <h2>Willkommen bei Bau Art Consulting</h2>    
        <p>Willkommen bei der Bau Art Consulting GmbH- Die Kunst des Bauens. Nach jahrelanger Erfahrung, als GmbH gegründet im Jahr 2022, steht unser Name für das Streben, das Bauen weiterzuentwickeln und stets normgerecht sowie nach dem neuesten Stand der Technik vorzugehen. Unsere Vision ist es, durch sorgfältige Planung und präzise Umsetzung Schäden zu verhindern und dabei einfache, pragmatische Lösungen zu bieten. Flexibilität, Termintreue und Bodenständigkeit sind die Grundpfeiler unserer Arbeit. <br></p>    
        <p>Unser Team besteht aus Experten, die mit Leidenschaft und Fachwissen individuelle Lösungen für jedes Bauprojekt erarbeiten. Wir legen großen Wert darauf, eng mit unseren Kunden zusammenzuarbeiten, um deren Vorstellungen und Wünsche in die Realität umzusetzen. Von der ersten Planungsphase bis zur finalen Fertigstellung sind wir Ihr verlässlicher Partner <br></p>    
        Überzeugen Sie sich selbst von unserer Expertise und unserem Engagement anhand unserer bisherigen Projekte. Unsere Fachleute stehen bereit, um Ihre Bauvorhaben zu verwirklichen. <a href="#contact">Kontaktieren Sie uns</a> noch heute, um mehr über unsere umfassenden Dienstleistungen zu erfahren und ein unverbindliches Angebot zu erhalten.    
      </div>    
    </section>    
    <section id="how-we-work" class="scroll-padding">    
      <div class="container">    
        <h2>Wie wir arbeiten</h2>    
        <p>Als Visionär im Bauwesen denken wir Bauprojekte immer wieder neu. Neue Technologien verändern Märkte und Arbeitsprozesse - auch im Baugewerbe. Um in einem zunehmend wettbewerbsintensiven Umfeld erfolgreich zu bleiben, legen wir großen Wert auf systematische Innovationen.</p>    
        <p>Unsere Forschung und Entwicklungen sorgen mit modernen Technologien und Verfahren für effiziente und kosteneffektive Problemlösungen, die hohe Bauqualität mit Wirtschaftlichkeit verbinden. Präzision und Transparenz sind für uns essenziell. Daher entwickeln wir Lösungen, die sowohl unsere internen Abläufe optimieren als auch unseren Kunden kontinuierliche Einblicke in den Fortschritt ihrer Projekte ermöglichen.</p>    
        <p>Bei unseren Dienstleistungen bleiben wir kompromislos traditionsbewusst. Wir schätzen Erfahrung und höchste Sorgfalt in der Arbeit und investieren viel Engagement in ihre Aus- und Fortbildung. Unser Ziel ist es, stets herausragende Qualität zu liefern, auf die Sie sich verlassen können.</p>    
      </div>    
    </section>    
    <section id="services" class="scroll-padding">    
      <div class="container">    
        <h2>Dienstleistungen</h2>    
        <div class="row">    
          <div class="col-md-6 col-lg-4 service-column">    
            <div class="service-tile animate__animated animate__fadeInLeft">    
              <div class="service-tile-image" :style="`background-image: url(${require('@/assets/projektentwicklung.png')})`"></div>    
              <div class="service-tile-content">    
                <h3>Projektentwicklung und -koordination</h3>    
                <ul class="bullet-points">    
                  <li>Ganzheitliche Planung, Machbarkeitsstudien und Kostenanalysen</li>    
                  <li>Unterstützung bei der Planung und Umsetzung von Neubauprojekten</li>    
                </ul>    
                <router-link to="/project-development" class="service-button">Mehr erfahren</router-link>  
              </div>    
              <div class="service-tile-image-overlay"></div>    
            </div>    
          </div>    
          <div class="col-md-6 col-lg-4 service-column">    
            <div class="service-tile animate__animated animate__fadeInLeftBig">    
              <div class="service-tile-image" :style="`background-image: url(${require('@/assets/projektüberwachung.png')})`"></div>    
              <div class="service-tile-content">    
                <h3>Bauleitung und Bauüberwachung</h3>    
                <ul class="bullet-points">    
                  <li>Überwachung und Koordination der Bauausführung</li>    
                  <li>Qualitätskontrolle und Dokumentation</li>    
                </ul>    
                <router-link to="/construction-supervision" class="service-button">Mehr erfahren</router-link>  
              </div>    
              <div class="service-tile-image-overlay"></div>    
            </div>    
          </div>    
          <div class="col-md-6 col-lg-4 service-column">    
            <div class="service-tile animate__animated animate__fadeInRightBig">    
              <div class="service-tile-image" :style="`background-image: url(${require('@/assets/statik.png')})`"></div>    
              <div class="service-tile-content">    
                <h3>Tragwerksplanung (Statik)</h3>    
                <ul class="bullet-points">    
                  <li>Statische Berechnungen und Konzepte für Neubauten und Sanierungen</li>    
                  <li>Erstellung von Tragwerksplänen</li>    
                  <li>Beratung zu Materialwahl und Bauverfahren</li>    
                </ul>    
                <router-link to="/structural-design" class="service-button">Mehr erfahren</router-link>  
              </div>    
              <div class="service-tile-image-overlay"></div>    
            </div>    
          </div>    
          <div class="col-md-6 col-lg-4 service-column">    
            <div class="service-tile animate__animated animate__fadeInLeft">    
              <div class="service-tile-image" :style="`background-image: url(${require('@/assets/messdienstleistungen.png')})`"></div>    
              <div class="service-tile-content">    
                <h3>Messdienstleistungen</h3>    
                <ul class="bullet-points">    
                  <li>Blower-Door-Test: Luftdichtheit prüfen</li>    
                  <li>Leckageortung: Luft- und Wasserlecks finden</li>    
                  <li>Thermografie: Wärmebildanalysen</li>    
                </ul>    
                <router-link to="/measurement-services" class="service-button">Mehr erfahren</router-link>  
              </div>    
              <div class="service-tile-image-overlay"></div>    
            </div>    
          </div>    
          <div class="col-md-6 col-lg-4 service-column">    
            <div class="service-tile animate__animated animate__fadeInRightBig">    
              <div class="service-tile-image" :style="`background-image: url(${require('@/assets/energieberatung.png')})`"></div>    
              <div class="service-tile-content">    
                <h3>Energieberatung</h3>    
                <ul class="bullet-points">    
                  <li>Gebäudeanalyse und Energieausweise</li>    
                  <li>Beratung zu Einsparmaßnahmen</li>    
                  <li>Unterstützung bei energetischen Sanierungen</li>    
                </ul>    
                <router-link to="/energy-consulting" class="service-button">Mehr erfahren</router-link>  
              </div>    
              <div class="service-tile-image-overlay"></div>    
            </div>    
          </div>    
          <div class="col-md-6 col-lg-4 service-column">    
            <div class="service-tile animate__animated animate__fadeInRight">    
              <div class="service-tile-image" :style="`background-image: url(${require('@/assets/sanierung.png')})`"></div>    
              <div class="service-tile-content">    
                <h3>Sanierungsbegleitung und Baumängelanalyse</h3>    
                <ul class="bullet-points">    
                  <li>Identifikation und Bewertung von Baumängeln</li>    
                  <li>Planung und Überwachung von Sanierungsmaßnahmen</li>    
                </ul>    
                <router-link to="/renovation-support" class="service-button">Mehr erfahren</router-link>  
              </div>    
              <div class="service-tile-image-overlay"></div>    
            </div>    
          </div>    
        </div>    
      </div>    
    </section>    
    <section id="reference" class="scroll-padding">    
      <div class="container">    
        <h2>Referenzen</h2>    
        <CustomerQuotes />    
      </div>    
    </section>    
    <section id="contact">    
      <div class="container">    
        <Contact />    
      </div>    
    </section>    
  </div>    
</template>    
    
<script>    
import CustomerQuotes from "@/components/CustomerQuotes.vue";    
import Contact from '@/components/Contact.vue';    
import main1 from "@/assets/main1.jpg";    
import main2 from "@/assets/main2.jpg";    
import main3 from "@/assets/main3.jpg";    
    
export default {    
  name: 'HomeView',    
  components: {    
    CustomerQuotes,    
    Contact,    
  },    
  data() {    
    return {    
      mainImages: [main1, main2, main3]    
    };    
  },    
  computed: {    
    randomMainImage() {    
      return this.mainImages[Math.floor(Math.random() * this.mainImages.length)];    
    }    
  },    
};    
</script>    
    
<style scoped>  #hero {  
  padding: 100px 0;  
}  
  
#about,  
#services,  
#reference {  
  padding: 50px 0;  
}  
  
h1,  
h2 {  
  margin-bottom: 20px;  
}  
  
.parallax {  
  background-attachment: fixed;  
  background-position: center;  
  background-repeat: no-repeat;  
  background-size: cover;  
  position: relative;  
  height: 100vh;  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  color: white;  
}  
  
.service-column {  
  margin-bottom: 40px; /* Mehr Abstand zwischen den Reihen */  
  display: flex;  
  justify-content: center;  
}  
  
.service-tile {  
  background-color: #f8f9fa;  
  border-radius: 10px;  
  padding: 20px;  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Schatten hinzugefügt */  
  width: 100%;  
  position: relative;  
  overflow: hidden;  
  transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s; /* Box-Shadow Transition hinzugefügt */  
  transform-origin: center; /* Transformationen aus der Mitte */  
  opacity: 1; /* Initial transparency removed */  
}  
  
.service-tile::before {  
  content: '';  
  display: block;  
  padding-top: 100%; /* 1:1 Aspect Ratio */  
}  
  
.service-tile:hover {  
  transform: scale(1.10); /* Leichtes Vergrößern beim Hover */  
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3); /* Schatten verstärken beim Hover */  
}  
  
.service-tile h3 {  
  margin-bottom: 10px;  
}  
  
.service-tile-content {  
  position: absolute;  
  top: 0;  
  left: 0;  
  right: 0;  
  bottom: 0;  
  z-index: 1;  
  text-align: center;  
  color: #fff;  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  flex-direction: column;  
  padding: 20px;  
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Increased text-shadow for better readability */  
}  
  
.service-button {  
  margin-top: 10px;  
  padding: 10px 20px;  
  background: linear-gradient(135deg, #CAFF5C, #F9EE6D, #FFAB61, #F37498);  
  background-size: 200% 200%;  
  animation: gradientAnimation 10s ease infinite;  
  border: none;  
  border-radius: 5px;  
  color: #ffffff; /* Button-Text-Farbe auf Weiß geändert */  
  text-decoration: none;  
  transition: background 0.3s, transform 0.3s;  
  opacity: 0.5; /* Initial transparency */  
}  
  
.service-button:hover {  
  transform: scale(1.10);  
  background-position: 100% 50%;  
  color: #ffffff;  
  opacity: 1; /* Increase opacity on hover */  
}  
  
.service-tile-image {  
  background-position: center;  
  background-repeat: no-repeat;  
  background-size: cover;  
  position: absolute;  
  top: 0;  
  left: 0;  
  width: 100%;  
  height: 100%;  
  opacity: 0.5; /* Blasser bei Nicht-Hover */  
  transition: opacity 0.3s;  
}  
  
.service-tile:hover .service-tile-image {  
  opacity: 0.8; /* Klarer bei Hover */  
}  
  
.service-tile-image-overlay {  
  background-color: rgba(0, 0, 0, 0.6); /* Increased initial transparency */  
  position: absolute;  
  top: 0;  
  left: 0;  
  width: 100%;  
  height: 100%;  
  opacity: 0.6; /* Increased initial transparency */  
  transition: opacity 0.3s;  
}  
  
.service-tile:hover .service-tile-image-overlay {  
  opacity: 0.4; /* Decrease opacity on hover for better readability */  
}  
  
.container {  
  margin-top: 30px;  
}  
  
h1.animate__animated {  
  font-size: 4rem;  
}  
  
p.animate__animated {  
  font-size: 2rem;  
}  
  
.title_text {  
  color: #b11616;  
  text-shadow: 1px 1px 2px #531e1e;  
}  
  
.bullet-points {  
  text-align: left;  
  padding-left: 20px;  
  color: #fff;  
}  
  
.bullet-points li {  
  margin-bottom: 10px;  
}  
  
@keyframes gradientAnimation {  
  0% {  
    background-position: 0% 50%;  
  }  
  50% {  
    background-position: 100% 50%;  
  }  
  100% {  
    background-position: 0% 50%;  
  }  
}  


</style>  
