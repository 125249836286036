<template>
  <div>
    <!-- Contact -->
    <div id="contact">
      <h2 class="my-4">So erreichen Sie uns</h2>
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="contact">
            <p>Bau Art Consulting GmbH</p>
            <p>Inhaber: Aykut Bosluk</p>
            <p>Telefon: +49 176 621 16170</p>
            <p>Email: <a href="mailto:info@bauartconsulting.de">info@bauartconsulting.de</a></p>
            <p>Speyerer Straße 4, 74078 Heilbronn, Deutschland</p>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>  
    
<script>
export default {
  name: 'ContactView',
}
</script>  

<style scoped>
.contact {
  text-align: center;
}

.contact p {
  margin-bottom: 5px;
}
</style> 
  