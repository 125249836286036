<template>  
    <div class="customer-quotes">  
      <div class="testimonial-container">  
        <transition name="arrow-left-fade">  
        <div class="arrow arrow-left" @click="prevQuote">  
          <font-awesome-icon icon="chevron-left" />  
        </div>  
      </transition> 
        <transition name="fly">  
          <div class="testimonial-tile" :key="activeQuoteIndex">  
            <div class="testimonial-text">  
              <font-awesome-icon icon="quote-left" />  
              {{ quotes[activeQuoteIndex].text }}  
              <font-awesome-icon icon="quote-right" />  
            </div>  
            <h4 class="testimonial-author">{{ quotes[activeQuoteIndex].author }}</h4>  
          </div>  
        </transition>  
        <transition name="arrow-right-fade">  
        <div class="arrow arrow-right" @click="nextQuote">  
          <font-awesome-icon icon="chevron-right" />  
        </div>  
      </transition>  
    </div>  
  </div>  
  </template>  
  
  
<script>
export default {
    name: "CustomerQuotes",
    data() {
        return {
            activeQuoteIndex: 0,
            quotes: [
                {
                    text: "Die einzige Sache, die wir bei unserer Kernsanierung bereuten, war, die Bau Art Consulting GmbH nicht schon früher kennengelernt zu haben. Das Team hat uns mit seiner Kompetenz und seinem Engagement stets zu unserer vollsten Zufriedenheit unterstützt. Wir können die Bau Art Consulting GmbH nur weiterempfehlen.",
                    author: "Bauherr Alex H. aus Weinsberg",
                },
                {
                  text: "Die einzige Sache, die wir bei unserer Kernsanierung bereuten, war, die Bau Art Consulting GmbH nicht schon früher kennengelernt zu haben. Das Team hat uns mit seiner Kompetenz und seinem Engagement stets zu unserer vollsten Zufriedenheit unterstützt. Wir können die Bau Art Consulting GmbH nur weiterempfehlen.",
                    author: "Bauherr Alex H. aus Weinsberg",
                },
                {
                  text: "Die einzige Sache, die wir bei unserer Kernsanierung bereuten, war, die Bau Art Consulting GmbH nicht schon früher kennengelernt zu haben. Das Team hat uns mit seiner Kompetenz und seinem Engagement stets zu unserer vollsten Zufriedenheit unterstützt. Wir können die Bau Art Consulting GmbH nur weiterempfehlen.",
                    author: "Bauherr Alex H. aus Weinsberg",
                },
            ],
        };
    },
    methods: {
        nextQuote() {
            this.activeQuoteIndex = (this.activeQuoteIndex + 1) % this.quotes.length;
        },
        prevQuote() {
            this.activeQuoteIndex =
                (this.activeQuoteIndex - 1 + this.quotes.length) % this.quotes.length;
        },
    },
};  
</script>  
    
<style scoped>  .customer-quotes {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
  }

  .fade-enter-active,
  .fade-leave-active {
      transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
      opacity: 0;
  }

  .quote-tile {
      background-color: #f8f9fa;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin-bottom: 30px;
      text-align: center;
      width: 100%;
      max-width: 600px;
      position: relative;
  }

  .quote-text {
      font-size: 16px;
      font-style: italic;
      position: relative;
  }

  .quote-text i {
      font-size: 20px;
      position: absolute;
      color: #e74c3c;
  }

  .fa-quote-left {
      top: -10px;
      left: -20px;
  }

  .fa-quote-right {
      bottom: -10px;
      right: -20px;
  }

  .arrows {  
    display: flex;  
    justify-content: center;  
    align-items: center;  
    margin-top: 20px;  
  }  
  
  .arrow {  
    font-size: 24px;  
    color: #333;  
    cursor: pointer;  
    display: flex;  
    justify-content: center;  
    align-items: center;  
    width: 40px;  
    height: 40px;  
    background-color: rgba(248, 249, 250, 0.8);  
    border-radius: 50%;  
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  
    margin-left: 40px;
    margin-right: 40px;
  }  
  
  .arrow:hover {  
    color: #e74c3c;  
  }  
  
  .arrow-left-fade-enter-active,  
  .arrow-left-fade-leave-active {  
    transition: all 0.5s;  
  }  
  .arrow-left-fade-enter-from,  
  .arrow-left-fade-leave-to {  
    transform: translateX(50%);  
    opacity: 0;  
  }  
  
  /* Fade effect for the right arrow */  
  .arrow-right-fade-enter-active,  
  .arrow-right-fade-leave-active {  
    transition: all 0.5s;  
  }  
  .arrow-right-fade-enter-from,  
  .arrow-right-fade-leave-to {  
    transform: translateX(50%);  
    opacity: 0;  
  }  
  
  .arrow i {  
    font-size: 24px;  
  }  

  .testimonial-tile {  
    background-color: #f8f9fa;  
    border-radius: 10px;  
    padding: 20px;  
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  
    margin-bottom: 30px;  
    text-align: center;  
    width: 100%;  
    max-width: 600px;  
    position: relative;  
    transition: transform 0.3s;  
  }  
  
  .testimonial-text {  
    font-size: 16px;  
    font-style: italic;  
    position: relative;  
  }  
  
  .testimonial-author {  
    font-weight: bold;  
    margin-top: 10px;  
    font-size: 16px;
  }  

  .customer-quotes {  
    display: flex;  
    justify-content: center;  
    align-items: center;  
    position: relative;  
  }  
  
  .customer-quotes {  
    display: flex;  
    justify-content: center;  
    align-items: center;  
    position: relative;  
  }  
  
  .testimonial-container {  
    display: flex;  
    align-items: center;  
  }  
  
  /* Update scale and transition effect for testimonial tiles */  
  .testimonial-tile:hover {  
    transform: scale(1.10);  
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);  
  }  
  
  /* Fly-in effect */  
  .fly-enter-active,  
  .fly-leave-active {  
    transition: all 0.5s;  
  }  
  
  .fly-enter-from,  
  .fly-leave-to {  
    transform: translateX(-50%);  
    opacity: 0;  
  }  
</style>  