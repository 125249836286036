<template>  
    <div class="container">  
      <h1>Bauleitung und Bauüberwachung</h1>  
      <p>Unsere Bauleitung und Bauüberwachung stellt sicher, dass Ihr Bauprojekt reibungslos und nach höchsten Standards verläuft. Wir überwachen die Bauausführung, koordinieren die verschiedenen Gewerke und sorgen für die Einhaltung von Qualität und Zeitplänen.</p>  
        
      <h2>Unsere Leistungen:</h2>  
      <ul>  
        <li>Überwachung und Koordination der Bauausführung</li>  
        <li>Qualitätskontrolle und Dokumentation</li>  
        <li>Kommunikation mit allen Beteiligten</li>  
        <li>Einhaltung der Sicherheitsvorschriften</li>  
        <li>Kontrolle der Baukosten und des Zeitplans</li>  
      </ul>  
        
      <h2>Warum wir?</h2>  
      <p>Unsere erfahrenen Bauleiter sind Ihre Ansprechpartner vor Ort und sorgen dafür, dass alle Arbeiten professionell und termingerecht ausgeführt werden. Wir setzen auf Transparenz und Präzision, um Ihnen ein Höchstmaß an Qualität und Zufriedenheit zu bieten.</p>  
        
      <p>Kontaktieren Sie uns noch heute, um mehr über unsere umfassenden Dienstleistungen zu erfahren und ein unverbindliches Angebot zu erhalten.</p>  
    </div>  
  </template>  
    
  <script>  
  export default {  
    name: 'ConstructionSupervision',  
  };  
  </script>  
    
  <style scoped>  
  .container {  
    margin-top: 100px;  
    padding: 20px;  
    line-height: 1.6;  
  }  
    
  h1, h2 {  
    margin-bottom: 10px;  
  }  
    
  ul {  
    list-style-type: disc;  
    padding-left: 20px;  
  }  
    
  li {  
    margin-bottom: 10px;  
  }  
    
  p {  
    margin-bottom: 10px;  
  }  
  </style>  
  