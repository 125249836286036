<template>      
  <nav class="navbar navbar-expand-lg fixed-top navbar-small">      
    <div class="container px-5">      
      <router-link class="navbar-brand" to="/"       
        @mouseover="isHovered = true"       
        @mouseout="isHovered = false">      
        <img :src="isHovered ? hoverLogo : defaultLogo" alt="BAC Logo" class="small-logo">      
      </router-link>      
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse"      
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"       
        aria-expanded="false" aria-label="Toggle navigation">      
        <span class="navbar-toggler-icon"></span>      
      </button>      
      <div class="collapse navbar-collapse" id="navbarSupportedContent">      
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">      
          <li>      
            <a class="nav-link" :href="fullBaseUrl('#about')" @click.prevent="scrollTo('#about')">Wir über uns</a>      
          </li>   
          <li>      
            <a class="nav-link" :href="fullBaseUrl('#how-we-work')" @click.prevent="scrollTo('#about')">Wie wir arbeiten</a>      
          </li>       
          <li>      
            <a class="nav-link" :href="fullBaseUrl('#services')" @click.prevent="scrollTo('#services')">Was wir leisten</a>      
          </li>      
          <li>      
            <a class="nav-link" :href="fullBaseUrl('#reference')" @click.prevent="scrollTo('#reference')">Was unsere Kunden sagen</a>      
          </li>
          <!--     Commented out the "Unsere Projekte" link -->  
          <!-- 
          <li>      
            <router-link class="nav-link" to="/projects">Unsere Projekte</router-link>      
          </li>
          -->      
          <li>      
            <a class="nav-link" :href="fullBaseUrl('#contact')" @click.prevent="scrollTo('#contact')">So erreichen Sie uns</a>      
          </li>      
          <li>      
            <router-link class="nav-link" to="/career">Wir stellen ein</router-link>      
          </li>   
        </ul>      
      </div>      
    </div>      
  </nav>      
</template>      
  
<script>      
import defaultLogo from '@/assets/BAC_logo_transparent.png'; // Default logo      
import hoverLogo from '@/assets/BAC_logo_transparent_white_text.png'; // Hover logo    
  
export default {      
  name: 'NavigationBar',      
  data() {      
    return {      
      isHovered: false,      
      defaultLogo,      
      hoverLogo      
    };      
  },      
  methods: {      
    scrollTo(selector) {      
      const targetUrl = this.fullBaseUrl(selector);      
      if (window.location.href === targetUrl) {      
        const element = document.querySelector(selector);      
        const yOffset = -50; // Adjust this value to change the offset      
        if (element) {      
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;      
          window.scrollTo({ top: y, behavior: 'smooth' });      
        }      
      } else {      
        window.location.href = targetUrl;      
      }      
    },      
    fullBaseUrl(selector) {      
      const { protocol, host } = window.location;      
      const baseUrl = `${protocol}//${host}`;      
      return `${baseUrl}/${selector}`;      
    },      
  }      
};      
</script>      
  
<style scoped>    
.navbar-small {    
    background: linear-gradient(135deg, #CAFF5C, #F9EE6D, #FFAB61, #F37498);    
    background-size: 200% 200%;    
    animation: gradientAnimation 10s ease infinite;    
    padding: 10px 0;    
    transition: all 0.3s;    
    opacity: 85%;    
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);    
    /* Shadow below the navbar */    
}    
  
@keyframes gradientAnimation {    
    0% {    
        background-position: 0% 50%;    
    }    
  
    50% {    
        background-position: 100% 50%;    
    }    
  
    100% {    
        background-position: 0% 50%;    
    }    
}    
  
.navbar-small .nav-link {    
    color: #595959;    
    /* White text color for navbar-small */    
}    
  
.navbar-small .nav-link:hover {    
    color: #ffffff;    
    /* Lighter shade of red for navbar-small hover color */    
    transition: color 0.3s;    
}    
  
.navbar-brand {    
    color: #ffffff;    
}    
  
.navbar-brand:hover {    
    color: #000;    
}    
  
.scroll-padding {    
    padding-top: 50px;    
    /* The same value as yOffset */    
    margin-top: -50px;    
    /* The negative value of yOffset */    
}    
  
.small-logo {    
    width: 130px;    
    height: auto;    
    /* Maintain aspect ratio */    
}    
  
@media (min-width: 992px) {    
    .navbar-collapse {    
        flex-grow: 0;    
    }    
}    
</style>    
