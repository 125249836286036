<!-- src/views/ProjectDetail.vue -->  
<template>  
    <div class="container">  
      <div id="app" class="project-detail-container">  
        <main class="project-detail">  
          <img :src="project.image" alt="">  
          <h1>{{ project.title }}</h1>  
          <div class="project-description">  
            <p>{{ project.description }}</p>  
          </div>  
        </main>  
      </div>  
    </div>  
  </template>  
    
  <script>  
    
  export default {  
    name: 'ProjectDetail',  

    data() {  
      return {  
        projects: [  
          {  
            id: 1,  
            title: 'Altbausanierung Weinsberg',  
            image: '@/assets/projects/altbausanierung_weinsberg.jpg',  
            description: 'Detailierte Beschreibung des Projekts Altbausanierung Weinsberg.',  
          },  
          {  
            id: 2,  
            title: 'Bau Fabrikhalle',  
            image: '@/assets/projects/bau_fabrikhalle.jpg',  
            description: 'Detailierte Beschreibung des Projekts Bau Fabrikhalle.',  
          },  
          {  
            id: 3,  
            title: 'Neubau EFH',  
            image: '@/assets/projects/neubau_efh.jpg',  
            description: 'Detailierte Beschreibung des Projekts Neubau EFH.',  
          },  
          {  
            id: 4,  
            title: 'Neubau MFH',  
            image: '@/assets/projects/neubau_mfh.jpg',  
            description: 'Detailierte Beschreibung des Projekts Neubau MFH.',  
          },  
        ],  
      };  
    },  
    computed: {  
      project() {  
        return this.projects.find(project => project.id === parseInt(this.$route.params.id));  
      }  
    }  
  }  
  </script>  
    
  <style scoped>  
  .project-detail-container {  
    max-width: 800px;  
    margin: 0 auto;  
    padding-top: 80px;  
    margin-top: 80px;  
    padding: 20px;  
    line-height: 1.6;  
  }  
    
  .project-detail img {  
    width: 100%;  
    border-radius: 10px;  
  }  
    
  .project-detail h1 {  
    color: #595959;  
  }  
    
  .project-description p {  
    margin-bottom: 10px;  
  }  
  </style>  
  