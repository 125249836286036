<template>
  <footer class="text-white mt-5 py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <p>&copy; {{ currentYear }} Bau Art Consulting GmbH. All Rights Reserved.</p>
        </div>
        <div class="col-md-6 text-md-right d-flex justify-content-end">
          <router-link class="footer-link" to="/impressum">Impressum</router-link>
          <router-link class="footer-link" to="/datenschutz">Datenschutz</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
};  
</script>

<style scoped>
footer {
  background: linear-gradient(135deg, #CAFF5C, #F9EE6D, #FFAB61, #F37498);
  background-size: 200% 200%; 
  animation: gradientAnimation 10s ease infinite;
  padding: 10px 0;
  transition: all 0.3s;
  opacity: 85%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Shadow below the navbar */
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.footer-link {
  margin-right: 15px;
  text-decoration: none;
  color: #595959;
}

.footer-link:hover {
  color: #ffffff;
  /* Change color on hover, same as navbar-small links */
  text-decoration: none;
  /* Remove underline on hover */
}

p {
  color: #595959;
}

</style>
